import { Workbox } from "workbox-window";
import { confirm } from "lib/notification";
import { getAuthStoreKeys } from "lib/session";

const authStorageKeys = getAuthStoreKeys();

export const register = () => {
  if ("serviceWorker" in navigator) {
    const wb = new Workbox("/service-worker.js");

    wb.addEventListener("installed", (event) => {
      if (event.isUpdate) {
        console.log("Another service worker installed!");

        wb.addEventListener("controlling", (event) => {
          // remove all local storage except for auth
          Object.entries(localStorage)
            .map((pair) => pair[0])
            .filter((key) => !authStorageKeys.includes(key))
            .forEach((key) => localStorage.removeItem(key));
        });

        confirm({ message: "Update beschikbaar. Bijwerken?" }).then(
          (confirmed) => {
            if (confirmed) {
              wb.addEventListener("controlling", (event) => {
                window.location.reload();
              });

              wb.messageSW({ type: "SKIP_WAITING" });
            }
          }
        );
      }
    });

    wb.register();
  }
};

export function unregister() {
  if ("serviceWorker" in navigator) {
    return navigator.serviceWorker.ready.then((registration) => {
      registration.unregister();
    });
  } else {
    return Promise.resolve();
  }
}
