import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import pick from "ramda/src/pick";
import prop from "ramda/src/prop";
import sortBy from "ramda/src/sortBy";
import * as Sentry from "@sentry/browser";
import { useForm, Controller } from "react-hook-form";

import { Select } from "lib/select";
import { ButtonSelect } from "lib/button-select";
import { useScrollToTop } from "lib/util";
import { get, post, Player } from "lib/api";

import { notify } from "lib/notification";

enum HalfTimeChange {
  None,
  Both,
  TeamOne,
  TeamTwo,
}

type NewMatchData = {
  team_one_player_back_id: number;
  team_one_player_front_id: number;
  team_two_player_back_id: number;
  team_two_player_front_id: number;
  team_one_half_time_switch: boolean;
  team_two_half_time_switch: boolean;
  team_one_final_score: number;
  team_two_final_score: number;
  team_one_half_time_score?: string;
  team_two_half_time_score?: string;
};

const createMatch = async (match: NewMatchData): Promise<any> => {
  const { request } = await post(`matches`, { match });

  return request;
};

export const GameRegistrationView: React.FC = () => {
  let history = useHistory();
  const initialPlayers =
    JSON.parse(window.localStorage.getItem("players")) || [];

  const [state, setState] = useState<{
    players: Player[];
    isSubmitting: false;
    halfTimeChange: HalfTimeChange;
  }>({
    players: initialPlayers,
    isSubmitting: false,
    halfTimeChange: HalfTimeChange.Both,
  });
  const mergeState = (newState) =>
    setState((prevState) => ({ ...prevState, ...newState }));

  useEffect(() => {
    let requestCancel;

    const fetchPlayers = async () => {
      const { abortController, request } = get("users");
      requestCancel = abortController;

      request.then((response) => {
        if (!response) {
          return; // request cancelled
        }

        const players = sortBy(prop("name"), response);
        window.localStorage.setItem("players", JSON.stringify(players));
        mergeState({ players });
      });
    };
    fetchPlayers();

    return () => {
      requestCancel.abort();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useScrollToTop();

  const {
    handleSubmit,
    control,
    register,
    errors,
    getValues,
    watch,
  } = useForm();

  const playerFields = [
    "team-one-player-back",
    "team-one-player-front",
    "team-two-player-back",
    "team-two-player-front",
  ];

  watch(playerFields);

  // excludes already selected players
  const playerOptions = (players, fieldName) => {
    const otherPlayerFields = playerFields.filter(
      (field) => field !== fieldName
    );
    const otherSelectedPlayerIds = Object.values(
      pick(otherPlayerFields, getValues())
    )
      .filter((value) => !!value)
      .map((selection) => selection.value);

    return players
      .filter((player) => !otherSelectedPlayerIds.includes(player.id))
      .map((player) => ({ value: player.id, label: player.name }));
  };

  const onSubmit = async (values) => {
    const data = {
      team_one_player_back_id: values["team-one-player-back"].value,
      team_one_player_front_id: values["team-one-player-front"].value,
      team_two_player_back_id: values["team-two-player-back"].value,
      team_two_player_front_id: values["team-two-player-front"].value,
      team_one_half_time_switch: [
        HalfTimeChange.Both,
        HalfTimeChange.TeamOne,
      ].includes(state.halfTimeChange),
      team_two_half_time_switch: [
        HalfTimeChange.Both,
        HalfTimeChange.TeamTwo,
      ].includes(state.halfTimeChange),
      team_one_final_score: values["team-one-final-score"],
      team_two_final_score: values["team-two-final-score"],
      team_one_half_time_score: values["team-one-half-time-score"] || null,
      team_two_half_time_score: values["team-two-half-time-score"] || null,
    };

    mergeState({ isSubmitting: true });

    try {
      await createMatch(data);
      mergeState({ isSubmitting: false });
      notify("Uitslag opgeslagen");
      history.push("/matches");
    } catch (error) {
      notify("Oeps. Er ging iets mis.");
      Sentry.addBreadcrumb({
        category: "xhr.data",
        level: Sentry.Severity.Info,
        message: JSON.stringify(data),
      });
      mergeState({ isSubmitting: false });
      throw error;
    }
  };

  const halfTimeChangeOptions = [
    { value: HalfTimeChange.None, label: "Geen" },
    { value: HalfTimeChange.Both, label: "Beide" },
    { value: HalfTimeChange.TeamOne, label: "Wit" },
    { value: HalfTimeChange.TeamTwo, label: "Zwart" },
  ];

  const handleHalfTimeChange = (value) => {
    mergeState({ halfTimeChange: Number.parseInt(value) });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="field">
        <label className="label" htmlFor="team-one-player-back">
          Wit achter
        </label>
        <div className="control">
          <Controller
            control={control}
            as={Select}
            name="team-one-player-back"
            options={playerOptions(state.players, "team-one-player-back")}
            isClearable={true}
            rules={{ required: true }}
            placeholder="Kies&hellip;"
            hasErrors={"team-one-player-back" in errors}
          />
        </div>
      </div>
      <div className="field">
        <label className="label" htmlFor="team-one-player-front">
          Wit voor
        </label>
        <div className="control">
          <Controller
            control={control}
            as={<Select />}
            name="team-one-player-front"
            options={playerOptions(state.players, "team-one-player-front")}
            isClearable={true}
            rules={{ required: true }}
            placeholder="Kies&hellip;"
            hasErrors={"team-one-player-front" in errors}
          />
        </div>
      </div>
      <div className="field">
        <label className="label" htmlFor="team-two-player-back">
          Zwart achter
        </label>
        <div className="control">
          <Controller
            control={control}
            as={<Select />}
            name="team-two-player-back"
            options={playerOptions(state.players, "team-two-player-back")}
            isClearable={true}
            rules={{ required: true }}
            placeholder="Kies&hellip;"
            hasErrors={"team-two-player-back" in errors}
          />
        </div>
      </div>
      <div className="field">
        <label className="label" htmlFor="team-two-player-front">
          Zwart voor
        </label>
        <div className="control">
          <Controller
            control={control}
            as={<Select />}
            name="team-two-player-front"
            options={playerOptions(state.players, "team-two-player-front")}
            isClearable={true}
            rules={{ required: true }}
            placeholder="Kies&hellip;"
            hasErrors={"team-two-player-front" in errors}
          />
        </div>
      </div>
      <div className="field">
        <label htmlFor="" className="label">
          Wissel bij 5?
        </label>
        <div className="control ">
          <ButtonSelect
            options={halfTimeChangeOptions}
            value={state.halfTimeChange}
            onChange={handleHalfTimeChange}
          />
        </div>
      </div>
      {state.halfTimeChange !== HalfTimeChange.None && (
        <>
          <div className="field">
            <label htmlFor="" className="label">
              Score bij wissel
            </label>
          </div>
          <div className="columns is-mobile">
            <div className="column">
              <div className="field is-grouped">
                <label htmlFor="" className="label">
                  Wit
                </label>
                <input
                  type="number"
                  className="input"
                  id="team-one-half-time-score"
                  name="team-one-half-time-score"
                  pattern="\d*"
                  ref={register({ required: true, min: 0, max: 99 })}
                  style={
                    "team-one-half-time-score" in errors
                      ? {
                          borderColor: "red",
                          borderWidth: "2px",
                        }
                      : {}
                  }
                />
              </div>
            </div>
            <div className="column">
              <div className="field is-grouped">
                <label htmlFor="" className="label">
                  Zwart
                </label>
                <input
                  type="number"
                  className="input"
                  id="team-two-half-time-score"
                  name="team-two-half-time-score"
                  pattern="\d*"
                  ref={register({ required: true, min: 0, max: 99 })}
                  style={
                    "team-two-half-time-score" in errors
                      ? {
                          borderColor: "red",
                          borderWidth: "2px",
                        }
                      : {}
                  }
                />
              </div>
            </div>
          </div>
        </>
      )}
      <div className="field">
        <label htmlFor="" className="label">
          Eindscore
        </label>
      </div>
      <div className="columns is-mobile">
        <div className="column">
          <div className="field is-grouped">
            <label htmlFor="" className="label">
              Wit
            </label>
            <input
              type="number"
              className="input"
              id="team-one-final-score"
              name="team-one-final-score"
              pattern="\d*"
              ref={register({ required: true, min: 0, max: 99 })}
              style={
                "team-one-final-score" in errors
                  ? {
                      borderColor: "red",
                      borderWidth: "2px",
                    }
                  : {}
              }
            />
          </div>
        </div>
        <div className="column">
          <div className="field is-grouped">
            <label htmlFor="" className="label">
              Zwart
            </label>
            <input
              type="number"
              className="input"
              id="team-two-final-score"
              name="team-two-final-score"
              pattern="\d*"
              ref={register({ required: true, min: 0, max: 99 })}
              style={
                "team-two-final-score" in errors
                  ? {
                      borderColor: "red",
                      borderWidth: "2px",
                    }
                  : {}
              }
            />
          </div>
        </div>
      </div>
      <motion.button
        type="submit"
        className="button is-primary is-fullwidth"
        disabled={state.isSubmitting}
        whileTap={{ scale: 0.95 }}
      >
        Opslaan
      </motion.button>
    </form>
  );
};
