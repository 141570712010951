import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Loader } from "react-feather";

import { useScrollToTop } from "lib/util";
import { get, Match } from "lib/api";
import { useOnVisible } from "lib/useOnVisible";

import styles from "./index.module.scss";
import { PullToReload } from "lib/pull-to-reload";

let requestCancel;

const fetchMatches = async mergeState => {
  const { abortController, request } = get("matches");
  requestCancel = abortController;

  request.then(response => {
    if (!response) {
      return; // request cancelled
    }

    window.localStorage.setItem("matches", JSON.stringify(response));
    mergeState({ matches: response });
  });
};

export const HistoryView: React.FunctionComponent = () => {
  const [firstRender, setFirstRender] = useState(true);

  const initialMatches =
    JSON.parse(window.localStorage.getItem("matches")) ||
    new Array(6).fill(null);

  const [state, setState] = useState<{ matches: Match[] }>({
    matches: initialMatches
  });
  const mergeState = newState =>
    setState(prevState => ({ ...prevState, ...newState }));

  useOnVisible(isVisible => {
    if (isVisible) {
      fetchMatches(mergeState);
    }
  });

  useEffect(() => {
    setFirstRender(false);
    fetchMatches(mergeState);

    return () => {
      requestCancel.abort();
    };
  }, [setFirstRender]);

  useScrollToTop();

  return (
    <PullToReload
      onReload={async () => {
        await fetchMatches(mergeState);
      }}
      labels={{ reload: "↓", reloading: "laden…", done: "↑" }}
    >
      <div className={styles.container}>
        {state.matches.map((match, idx) => {
          if (match) {
            return (
              <MatchView
                key={match.id}
                match={match}
                firstRender={firstRender}
              />
            );
          } else {
            return <MatchPlaceholderView key={idx} />;
          }
        })}
      </div>
    </PullToReload>
  );
};

const MatchView: React.FunctionComponent<{
  match: Match;
  firstRender: boolean;
}> = ({ match, firstRender = false }) => (
  <motion.div
    className={styles.match}
    initial={{ opacity: 0, y: firstRender ? 0 : "-100%" }}
    animate={{ opacity: 1, y: 0 }}
    transition={{
      delay: firstRender ? 0 : 0.5
    }}
  >
    <div className={styles.teamOne}>
      <div className={styles.playerBack}>{match.team_one_player_back.name}</div>
      <div className={styles.playerFront}>
        {match.team_one_player_front.name}
      </div>
      <div className={styles.result}>
        <div className={styles.teamName}>wit</div>
        <div className={styles.finalScore}>{match.team_one_final_score}</div>
        <div className={styles.halfTimeScore}>
          {match.team_one_half_time_score}
        </div>
      </div>
    </div>
    <div className={styles.teamTwo}>
      <div className={styles.playerBack}>{match.team_two_player_back.name}</div>
      <div className={styles.playerFront}>
        {match.team_two_player_front.name}
      </div>
      <div className={styles.result}>
        <div className={styles.teamName}>zwart</div>
        <div className={styles.finalScore}>{match.team_two_final_score}</div>
        <div className={styles.halfTimeScore}>
          {match.team_two_half_time_score}
        </div>
      </div>
    </div>
    <div className={styles.time}>
      {new Date(match.inserted_at).toLocaleString()}
    </div>
  </motion.div>
);

const MatchPlaceholderView: React.FunctionComponent = () => (
  <div className={styles.match} style={{ background: "none" }}>
    <motion.div
      animate={{ rotate: 360 }}
      transition={{
        loop: Infinity,
        ease: "linear",
        duration: 2
      }}
      style={{ height: "24px", opacity: 0.5 }}
    >
      <Loader />
    </motion.div>
  </div>
);
