import React from "react";

export const ButtonSelect: React.FC<{
  options: ButtonOption[];
  value: number | string;
  onChange: (value: number | string) => any;
}> = ({ options, value, onChange }) => {
  return (
    <div className="buttons has-addons">
      {options.map((option) => (
        <button
          key={option.value}
          type="button"
          className={`button ${value === option.value ? "is-active" : ""}`}
          onClick={() => onChange(option.value)}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};

type ButtonOption = {
  value: string | number;
  label: string;
};
