/* eslint @typescript-eslint/no-unused-vars: 0 */
import React, { useEffect, useState } from "react";
import mergeDeepRight from "ramda/es/mergeDeepRight";

import { get, logout as apiLogout } from "lib/api";
import { confirm } from "lib/notification";
import { useScrollToTop } from "lib/util";
import { PullToReload } from "lib/pull-to-reload";
import { useOnVisible } from "lib/useOnVisible";

import styles from "./index.module.scss";

const avgOrZero = (amount: number, total: number, digits = 0): string =>
        total > 0 ? ((amount / total) * 100).toFixed(digits) + "%" : "0%";

const ratio = (amount: number, total: number, digits = 2): string =>
        total > 0 ? (amount / (total - amount)).toFixed(digits) : "0";

let profile, requestCancel;

const fetchProfile = async ({ mergeState, state }) => {
        const { abortController, request } = get("profile");
        requestCancel = abortController;

        request.then(response => {
                if (!response) {
                        return; // request cancelled
                }

                profile = mergeDeepRight(state.profile, response);
                window.localStorage.setItem("profile", JSON.stringify(profile));
                mergeState({ profile });
        });
};

export const ProfileView: React.FunctionComponent = () => {
        const storedProfile = JSON.parse(window.localStorage.getItem("profile"));
        const defaultProfile = {
                player: {
                        id: 0,
                        name: " "
                },
                matches: {
                        wins: {
                                total: 0,
                                amount: 0
                        },
                        points: {
                                total: 0,
                                amount: 0
                        }
                },
                positionBack: {
                        wins: {
                                total: 0,
                                amount: 0
                        },
                        points: {
                                total: 0,
                                amount: 0
                        }
                },
                positionFront: {
                        wins: {
                                total: 0,
                                amount: 0
                        },
                        points: {
                                total: 0,
                                amount: 0
                        }
                }
        };

        const [state, setState] = useState({
                profile: storedProfile || defaultProfile
        });
        const mergeState = newState =>
                setState(prevState => ({ ...prevState, ...newState }));

        const logout = async () => {
                if (await confirm({ message: "Weet je het zeker?" })) {
                        apiLogout();
                }
        };

        useOnVisible(isVisible => {
                if (isVisible) {
                        fetchProfile({ state, mergeState });
                }
        });

        useEffect(() => {
                fetchProfile({ state, mergeState });

                return () => {
                        requestCancel.abort();
                };

                // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        useScrollToTop();

        return (
                <PullToReload
                        onReload={async () => {
                                await fetchProfile({ state, mergeState });
                        }}
                        labels={{ reload: "↓", reloading: "laden…", done: "↑" }}
                >
                        <div className={styles.container}>
                                <h1 className={styles.profileTitle}>{state.profile.player.name}</h1>
                                <MetricGroupView title="Wedstrijden" data={state.profile.matches} />
                                <MetricGroupView
                                        title="Positie achter"
                                        data={state.profile.positionBack}
                                />
                                <MetricGroupView
                                        title="Positie voor"
                                        data={state.profile.positionFront}
                                />
                                <div
                                        style={{
                                                display: "flex",
                                                justifyContent: "space-evenly",
                                                width: "100%"
                                        }}
                                >
                                        <button
                                                className="button is-outlined is-small"
                                                style={{ margin: "3rem 0 1rem" }}
                                                type="button"
                                                onClick={() => {
                                                        window.location.reload();
                                                }}
                                                tabIndex={-1}
                                        >
                                                check voor update
                                        </button>
                                        <button
                                                className="button is-outlined is-small"
                                                style={{ margin: "3rem 0 1rem" }}
                                                type="button"
                                                onClick={logout}
                                                tabIndex={-1}
                                        >
                                                afmelden
                                        </button>
                                </div>
                        </div>
                </PullToReload>
        );
};

const MetricGroupView: React.FunctionComponent<{
        title: string;
        data: any;
}> = ({ title, data }) => (
        <div className={styles.group}>
                <h2 className="subtitle">{title}</h2>
                <div className={styles.dimension}>
                        <div className={styles.dimensionHeading}>Gewonnen</div>
                        <div className={styles.avg}>
                                {avgOrZero(data.wins.amount, data.wins.total)}
                        </div>
                        <div className={styles.totals}>
                                {data.wins.amount} winst / {data.wins.total} totaal
                        </div>
                </div>
                <div className={styles.dimension}>
                        <div className={styles.dimensionHeading}>Punten ratio</div>
                        <div className={styles.avg}>
                                {ratio(data.points.amount, data.points.total)}
                        </div>
                        <div className={styles.totals}>
                                {data.points.amount} voor / {data.points.total - data.points.amount}{" "}
                                tegen
                        </div>
                </div>
        </div>
);
