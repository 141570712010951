export class Observer {
  subscribers: Set<(notification: any) => void>;

  constructor() {
    this.subscribers = new Set();
  }

  notify(notification: any) {
    this.subscribers.forEach(subscriber => subscriber(notification));
  }

  async asyncNotifyAll(notification: any): Promise<any> {
    const promises = Array.from(this.subscribers).map(subscriber => {
      return Promise.resolve(subscriber(notification));
    });

    return Promise.all(promises);
  }

  subscribe(subscriber: (notification: any) => void) {
    this.subscribers.add(subscriber);
  }

  unsubscribe(subscriber: (notification: any) => void) {
    this.subscribers.delete(subscriber);
  }
}
