import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  NavLink
} from "react-router-dom";
import { Transition } from "react-spring/renderprops";
import { PlusSquare, Calendar, Award, User } from "react-feather";

import "./App.scss";
import { isAuthenticated } from "lib/session";
import { sessionAutoRenewal, renewLogin, connectionManager } from "lib/api";
import { GameRegistrationView } from "./modules/game-registration-view";
import { HistoryView } from "./modules/history-view";
import { RankingsView } from "./modules/rankings-view";
import { LoginView } from "./modules/login-view";
import { ProfileView } from "./modules/profile";
import { NotificationContainer, notify } from "./lib/notification";
import { useOnVisible } from "lib/useOnVisible";
import { useOnlineStatus } from "lib/useOnlineStatus";

import matchBg from "./modules/history-view/match-bg.svg";

function App() {
  const [isConnecting, setIsConnecting] = useState(false);

  useOnVisible(isVisible => {
    if (isVisible) {
      renewLogin();
    }
  });

  useOnlineStatus(isOnline => {
    notify(isOnline ? "Online" : "You are offline");
  });

  useEffect(() => {
    const renewalCleanup = sessionAutoRenewal();
    connectionManager.subscribe(setIsConnecting);

    return () => {
      renewalCleanup();
      connectionManager.unsubscribe(setIsConnecting);
    };
  }, []);

  return (
    <ErrorBoundary>
      <div className="App">
        <Transition
          items={isConnecting}
          from={{
            opacity: 0,
            width: "0vw"
          }}
          enter={{ opacity: 1, width: "85vw", duration: 1000 }}
          leave={{ opacity: 0, width: "100vw" }}
        >
          {show =>
            show && (props => <div className="is-connecting" style={props} />)
          }
        </Transition>
        <Router>
          {isAuthenticated() ? (
            <>
              <Switch>
                <Route path="/add-match">
                  <GameRegistrationView />
                </Route>
                <Route path="/matches">
                  <HistoryView />
                </Route>
                <Route path="/rankings">
                  <RankingsView />
                </Route>
                <Route path="/profile">
                  <ProfileView />
                </Route>
                <Route path="/">
                  <Redirect to="/add-match" />
                </Route>
              </Switch>

              <nav
                className="navbar is-fixed-bottom"
                role="navigation"
                aria-label="main navigation"
              >
                <div className="navbar-brand">
                  <NavLink
                    to="/add-match"
                    className="navbar-item"
                    activeClassName="is-active"
                  >
                    <PlusSquare />
                    Invoer
                  </NavLink>
                  <NavLink
                    to="/matches"
                    className="navbar-item"
                    activeClassName="is-active"
                  >
                    <Calendar />
                    Uitslagen
                  </NavLink>
                  <NavLink
                    to="/rankings"
                    className="navbar-item"
                    activeClassName="is-active"
                  >
                    <Award />
                    Klassement
                  </NavLink>
                  <NavLink
                    to="/profile"
                    className="navbar-item"
                    activeClassName="is-active"
                  >
                    <User />
                    Profiel
                  </NavLink>
                </div>
              </nav>
            </>
          ) : (
            <LoginView />
          )}
        </Router>

        <NotificationContainer />
        <div
          id="preloader"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            transform: "translateY(-10000px)"
          }}
        >
          <div
            style={{
              background: `0 0  url(${matchBg}) no-repeat`,
              fontWeight: 600
            }}
          >
            preload history view assets
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
}

class ErrorBoundary extends React.Component {
  componentDidCatch(error, errorInfo) {
    notify("Oeps, er ging iets mis.");
    console.error("error:", error, errorInfo);
  }

  render() {
    return this.props.children;
  }
}

export default App;
