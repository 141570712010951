import { useEffect, useState } from "react";

// Don't use for now. Navigator.onLine is unreliable:
// https://stackoverflow.com/q/14283124/159086
export const useOnlineStatus = (
  handleOnlineChange?: (isOnline: boolean) => void
) => {
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);

  useEffect(() => {
    const updateIsOnline = (): void => {
      setIsOnline(navigator.onLine);

      if (handleOnlineChange) {
        handleOnlineChange(navigator.onLine);
      }
    };

    window.addEventListener("offline", updateIsOnline, false);
    window.addEventListener("online", updateIsOnline, false);

    return () => {
      window.removeEventListener("offline", updateIsOnline);
      window.removeEventListener("online", updateIsOnline);
    };
  }, [handleOnlineChange]);

  return isOnline;
};
