import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import produce from "immer";
import identity from "ramda/es/identity";

import { Observer } from "lib/Observer";
import styles from "./index.module.scss";

enum NotificationType {
  notice,
  confirm
}

interface Notification {
  id: string;
  type: NotificationType;
  message: string;
  cancel: string;
  confirm?: string;
  resolve?: any;
  reject?: any;
}

const randomId = (): string =>
  (Math.random().toString(36) + Date.now().toString(36)).substr(2, 10);

export const notify = (message: string): void =>
  notificationManager.notify({
    id: randomId(),
    type: NotificationType.notice,
    message,
    cancel: "sluiten"
  });
(window as any).notify = notify;

export const confirm = async ({
  message,
  cancel = "annuleren",
  confirm = "Oké"
}: {
  message: string;
  cancel?: string;
  confirm?: string;
}): Promise<boolean> => {
  const results = await notificationManager.asyncNotifyAll({
    id: randomId(),
    type: NotificationType.confirm,
    message,
    cancel,
    confirm
  });

  return results.every(identity);
};

export const notificationManager = new Observer();

export const NotificationContainer: React.FunctionComponent = () => {
  const [notifications, setNotifications] = useState<Set<Notification>>(
    new Set<Notification>()
  );

  const addNotification = async (notification: Notification) => {
    const notificationPromise = new Promise((resolve, reject) => {
      notification.resolve = resolve;
      notification.reject = reject;
    });

    setNotifications(
      produce(next => {
        next.add(notification);
      })
    );

    return notificationPromise;
  };

  const deleteNotification = (notification: Notification) => {
    if (notification.resolve) {
      notification.resolve(false);
    }

    setNotifications(
      produce(next => {
        next.delete(notification);
      })
    );
  };

  const confirmNotification = (notification: Notification) => {
    if (notification.resolve) {
      notification.resolve(true);
    }

    setNotifications(
      produce(next => {
        next.delete(notification);
      })
    );
  };

  useEffect(() => {
    notificationManager.subscribe(addNotification);

    return () => {
      notificationManager.unsubscribe(addNotification);
    };
  }, []);

  return (
    <div className={styles.container}>
      {Array.from(notifications).map((notification, idx) => (
        <NotificationView
          key={notification.id}
          notification={notification}
          onClosed={deleteNotification}
          onConfirm={confirmNotification}
        />
      ))}
    </div>
  );
};

const NotificationView: React.FunctionComponent<{
  notification: Notification;
  onClosed: any;
  onConfirm?: (notifcation: Notification) => void;
}> = ({ notification, onConfirm, onClosed }) => {
  const [active, setActive] = useState<boolean>(true);

  const animationVariants = {
    inactive: { opacity: 0, y: 150 },
    active: { opacity: 1, y: 0 }
  };

  const handleNotificationClose = () => {
    setActive(false);
  };

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm(notification);
    }
  };

  const handleAnimationComplete = () => {
    if (!active) {
      onClosed(notification);
    }
  };

  useEffect(() => {
    if (notification.type !== NotificationType.confirm) {
      const timeout = 3 * 1000;
      const id = window.setTimeout(handleNotificationClose, timeout);

      return () => {
        window.clearTimeout(id);
      };
    }
  }, [notification.type]);

  return (
    <motion.div
      initial="inactive"
      animate={active ? "active" : "inactive"}
      variants={animationVariants}
      onAnimationComplete={handleAnimationComplete}
    >
      <div className={styles.notification}>
        <div className={styles.notificationContent}>{notification.message}</div>
        <div className={styles.actions}>
          <button
            type="button"
            className={`button is-dark ${styles.closeButton}`}
            onClick={handleNotificationClose}
          >
            {notification.cancel}
          </button>
          {notification.type === NotificationType.confirm && (
            <button
              type="button"
              className={`button is-light ${styles.confirmButton}`}
              onClick={handleConfirm}
            >
              {notification.confirm}
            </button>
          )}
        </div>
      </div>
    </motion.div>
  );
};
