import { useEffect } from "react";

export const useOnVisible = (handleOnVisible: (isVisible: boolean) => void) => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      handleOnVisible(document.visibilityState === "visible");
    };

    document.addEventListener(
      "visibilitychange",
      handleVisibilityChange,
      false
    );

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [handleOnVisible]);
};
