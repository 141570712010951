import React from "react";
import ReactSelect from "react-select";

const styles = (hasErrors = false) => {
  const controlErrorStyles = hasErrors
    ? { borderColor: "red", borderWidth: "2px" }
    : {};

  return {
    menu: provided => ({
      ...provided,
      zIndex: 4
    }),
    control: provided => ({
      ...provided,
      borderColor: "black",
      ...controlErrorStyles
    })
    // NOTE: Setting the following styles do not seem to work.
    // indicatorContainer: provided => ({
    //   ...provided,
    //   color: "black"
    // }),
    // indicatorSeparator: provided => ({
    //   ...provided,
    //   backgroundColor: "black"
    // })
  };
};

export const Select: React.FC<{
  hasErrors?: boolean;
} & ReactSelect.props> = ({ hasErrors, ...props }) => (
  <ReactSelect styles={styles(hasErrors)} {...props} />
);
