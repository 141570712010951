import React, { useState } from "react";
import { motion } from "framer-motion";

import { unregister as unregisterServiceWorker } from "service-worker/registration";
import { login } from "lib/api";
import { notify } from "lib/notification";

export const LoginView: React.FunctionComponent = () => {
  const [state, setState] = useState<{ isSubmitting: boolean }>({
    isSubmitting: false
  });

  const mergeState = newState =>
    setState(prevState => ({ ...prevState, ...newState }));

  const handleSubmit = async evt => {
    evt.preventDefault();
    const email = evt.target.elements.namedItem("login-email").value;
    const password = evt.target.elements.namedItem("login-password").value;

    mergeState({ isSubmitting: true });

    try {
      await login({ email, password });
      mergeState({ isSubmitting: false });
      window.location.reload();
    } catch {
      notify("Verkeerde wachtwoord of email");
      window.localStorage.clear();
      await unregisterServiceWorker();
      mergeState({ isSubmitting: false });
    }

    return false;
  };

  return (
    <form onSubmit={handleSubmit}>
      <h1 className="title">Office Foosball</h1>
      <div className="field">
        <label className="label" htmlFor="login-email">
          Email
        </label>
        <div className="control">
          <input
            className="input"
            type="text"
            name="login-email"
            id="login-email"
            autoComplete="username"
          />
        </div>
      </div>
      <div className="field">
        <label className="label" htmlFor="login-password">
          Wachtwoord
        </label>
        <div className="control">
          <input
            className="input"
            type="password"
            name="login-password"
            id="login-password"
            autoComplete="current-password"
          />
        </div>
      </div>
      <motion.button
        type="submit"
        className="button is-primary is-fullwidth"
        disabled={state.isSubmitting}
        whileTap={{ scale: 0.95 }}
      >
        Verder
      </motion.button>
    </form>
  );
};
