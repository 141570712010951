import { useEffect } from "react";

// timeout using a promise
export const timeout = <T extends {} | undefined>(value: T, ms = 1000) =>
  new Promise<T>(resolve => window.setTimeout(resolve, ms, value));

export const useScrollToTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);
};
