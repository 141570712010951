export const register = () => {
  var _paq = window._paq || [];
  _paq.push(["trackPageView"]);
  _paq.push(["enableLinkTracking"]);

  (function() {
    var u = "//growth.getlean.digital/";
    _paq.push(["setTrackerUrl", u + "m.php"]);
    _paq.push(["setSiteId", "2"]);

    var d = document,
      g = d.createElement("script"),
      s = d.getElementsByTagName("script")[0];

    g.type = "text/javascript";
    g.async = true;
    g.defer = true;
    g.src = u + "m.js";
    s.parentNode.insertBefore(g, s);
  })();
};
