import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { register as registerAnalytics } from "lib/analytics";
// import { register as registerServiceWorker } from "service-worker/registration";

import * as Sentry from "@sentry/browser";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
// serviceWorker.unregister();
// registerServiceWorker();

if (
  process.env.NODE_ENV === "production" &&
  !process.env.REACT_APP_DISABLE_TRACKING
) {
  registerAnalytics();
  const config = {
    dsn: "https://fa11059896e84783bd82cefb8c0844b1@sentry.io/1835519",
    release: process.env.REACT_APP_RELEASE_VERSION
  };
  Sentry.init(config);
}
