import React, { useState, useEffect } from "react";
import { useDrag, useScroll } from "react-use-gesture";
import { useSpring, animated } from "react-spring";

import { timeout } from "lib/util";

enum PullToReloadState {
  Inactive = "INACTIVE",
  Pulling = "PULLING",
  PastThreshold = "PAST_THRESHOLD",
  Reloading = "RELOADING",
  Done = "DONE",
}

const dragResistanceDefault = (movementY: number): number =>
  Math.ceil(movementY / 1.5);

export const PullToReload: React.FC<{
  verbose?: boolean;
  threshold?: number;
  maxDrag?: number;
  reloaderHeight?: number;
  dragResistance?: (movementY: number) => number;
  reloadDelayMs?: number;
  labels?: {
    reload?: React.ReactNode | string;
    reloading?: React.ReactNode | string;
    done?: React.ReactNode | string;
  };
  onReload: () => Promise<any>; //<T>() => Promise<T | undefined | void>;
}> = ({
  verbose = false,
  threshold = 60,
  maxDrag = 120,
  reloaderHeight = 80,
  dragResistance = dragResistanceDefault,
  reloadDelayMs = 250,
  onReload,
  labels = {
    reload: "↓",
    reloading: "reloading…",
    done: "↑",
  },
  children,
}) => {
  const [reloadState, setReloadState] = useState(PullToReloadState.Inactive);
  const [atPageTop, setAtPageTop] = useState(true);
  const [containerStyle, setContainerStyle] = useSpring(() => ({
    from: { willChange: "transform" },
    to: { transform: `translateY(0px)` },
  }));
  const [reloaderStyle, setReloaderStyle] = useSpring(() => ({
    from: {
      height: `${reloaderHeight}px`,
      position: "absolute",
      top: 0,
      width: "calc(100% - 2rem)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      willChange: "transform",
    },
    to: {
      transform: `translateY(${-1 * reloaderHeight}px)`,
      color: "gray",
    },
  }));

  const bindDrag = useDrag(
    ({ first, last, movement: [, movementY], memo }) => {
      if (verbose) {
        console.debug(`movementY`, movementY);
      }

      if (!atPageTop) {
        if (verbose) {
          console.debug(`dragging but not at page top`);
        }

        return memo;
      }

      if (first) {
        if (verbose) {
          console.debug(`first drag`);
        }

        setContainerStyle({ to: { transform: `translateY(0)` } });
        setReloaderStyle({
          to: {
            transform: `translateY(${-1 * reloaderHeight}px)`,
            color: "gray",
          },
        });
        setReloadState(PullToReloadState.Pulling);
      }

      const newY = dragResistance(movementY);

      if (last) {
        if (verbose) {
          console.debug(`last drag`);
        }

        if (reloadState === PullToReloadState.PastThreshold) {
          if (verbose) {
            console.debug(`last & past threshold`);
          }
          setReloadState(PullToReloadState.Reloading);
          setContainerStyle({
            to: { transform: `translateY(${reloaderHeight}px)` },
          });
          setReloaderStyle({
            to: { transform: `translateY(0)`, color: "gray" },
          });

          onReload().finally(() => {
            // delay otherwise users think it didnt actually reload
            timeout(undefined, reloadDelayMs).finally(() => {
              setReloadState(PullToReloadState.Done);
              setContainerStyle({ to: { transform: `translateY(0)` } });
              setReloaderStyle({
                to: {
                  transform: `translateY(${-1 * reloaderHeight}px)`,
                  color: "gray",
                },
              });
            });
          });
        } else {
          if (verbose) {
            console.debug(`last but not far enough`);
          }
          setReloadState(PullToReloadState.Inactive);
          setContainerStyle({ to: { transform: `translateY(0)` } });
          setReloaderStyle({
            to: {
              transform: `translateY(${-1 * reloaderHeight}px)`,
              color: "gray",
            },
          });
        }
      } else {
        if (movementY < 0) {
          return memo;
        }

        if (newY >= threshold && memo < threshold) {
          if (verbose) {
            console.debug(`past threshold set`);
          }
          // only set this reload state once
          setReloadState(PullToReloadState.PastThreshold);
        }

        setContainerStyle({ to: { transform: `translateY(${newY}px)` } });
        setReloaderStyle({
          to: {
            transform: `translateY(${-1 * reloaderHeight + newY}px)`,
            color: newY >= threshold ? "black" : "gray",
          },
        });

        return newY;
      }
    },
    {
      rubberband: true,
      lockDirection: true,
      axis: "y",
      bounds: { top: 0, bottom: maxDrag },
    }
  );

  const scroll = useScroll(
    () => {
      setAtPageTop(window.pageYOffset < 1);
    },
    { domTarget: window }
  );
  useEffect(scroll, [scroll]);

  // disable android/chrome's own pull to refresh
  const setBodyCss = () => {
    // @ts-ignore
    document.documentElement.style["overscroll-behavior"] = "contain";

    return () => {
      // @ts-ignore
      document.documentElement.style["overscroll-behavior"] = "";
    };
  };
  useEffect(setBodyCss, []);

  const refresherLabel = (state: PullToReloadState) => {
    switch (state) {
      case PullToReloadState.Reloading:
        return labels.reloading;
      case PullToReloadState.Done:
        return labels.done;
      default:
        return labels.reload;
    }
  };

  return (
    <>
      <animated.div style={reloaderStyle}>
        <div>{refresherLabel(reloadState)}</div>
      </animated.div>
      <animated.div style={containerStyle} {...bindDrag()}>
        {/* {atPageTop ? "at top" : "scrolled"} */}
        {children}
      </animated.div>
    </>
  );
};
